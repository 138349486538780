<template>
    <section id="history" class="py-5">
        <the-banner-small>Ontstaan</the-banner-small>
        <div class="container my-3">
            <div class="row">
                <div class="col-12">
                    <p>
                        Het idee om een steunfonds voor minderbedeelden op te richten, is ontstaan in de periode 2007/2008. Aanleiding hiertoe waren de  smeekbeden om geld of voeding van (arme) mensen,
                        die op eerder onregelmatige basis aan de deur van de Berlaarse pastorie kwamen kloppen. Tussen de toenmalige pastoor E.H. Marc Van Steen, Raoul Verhaeven (huidig diaken) en
                        Gaby Coucquyt werd hierdoor het plan opgevat om een antwoord te geven aan deze vragen naar ondersteuning en om deze ondersteuning in een vaste vorm te gieten.
                    </p>
                    <p>
                        Deze gesprekken resulteerden in de oprichting van een noodfonds, als parochiaal initiatief en als afdeling van de vzw Parochiale Werken van Berlare,
                        zodat dit noodfonds een vaste “juridische” vorm kreeg. We schrijven 2008 en het Sint-Martinusfonds (SMF) ging van start. In de groei van het Sint-Martinusfonds naar
                        een efficiënte werking, heeft het fonds hier en daar een paar kinderziekten kunnen ontwijken, dank zij het advies van juristen en verzekeraars.
                        Het Sint-Martinusfonds werkt volledig autonoom, in een volledig zelfstandige omgeving, maar als afdeling van de vzw Parochiale Werken.
                    </p>
                    <p>
                        In 2010 werden naast het contactpunt te Berlare, twee bijkomende contact/verdeelpunten van het Sint-Martinusfonds opgericht: in Uitbergen en in Overmere.
                        Deze afdelingen werken in het verlengde van het Sint-Martinusfonds Berlare.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TheBannerSmall from '../components/layout/TheBannerSmall'

export default {
    components: {
        TheBannerSmall
    }
}
</script>

<style>

</style>
